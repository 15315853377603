/* eslint-disable jsx-a11y/anchor-is-valid */
// Dependencies
import React from 'react';

import {
  faPrint, faEnvelope,faSearch
} from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './StaticHeader.css';

export default function StaticHeader() {
  if(window.innerWidth < 420){
    return (
      <section className="static-header">
        <div className="static-header-menulist">
            <div className="downloadVoucherIcon">
              <FontAwesomeIcon className="static-header-menulist-FontAwesomeIcon" size="2x" icon={faPrint} />
            </div>
            {/* <a className="downloadVoucher" href="/descargarcomprobante"> Descarga tu Comprobante</a> */}
            <a className="downloadVoucher" href="http://ifacturacion.pe/descargarcomprobante"> Descarga tu Comprobante</a>
            <div className="phoneIcon">
              <FontAwesomeIcon className="static-header-menulist-FontAwesomeIcon" size="2x" icon={faWhatsapp} />
            </div>
            <a className="phone" href="https://api.whatsapp.com/send?phone=+51984125256&amp;text=Quiero%20saber%20mas%20de%20iFacturacion"> 984 125 256</a>
        </div>
      </section>
    );
  }else{
    return (
      <section className="static-header">
        <ul className="static-header-menulist">
          <li>
            <FontAwesomeIcon  icon={faPrint} />
            {/* <a href="/descargarcomprobante"> Descarga tu Comprobante</a> */}
            <a href="http://ifacturacion.pe/descargarcomprobante"> Descarga tu Comprobante</a>
          </li>
          <li>
            <FontAwesomeIcon  icon={faSearch} />
            <a href="/empresa-obligada"> Empresa Obligada</a>
          </li>
          <li>
            <FontAwesomeIcon  icon={faEnvelope} />
           <a href="mailto:info@palerp.com"> Info@palerp.com</a>
          </li>
          <li>
            <FontAwesomeIcon  size="1.8x" icon={faWhatsapp}/>
            <a  href="https://api.whatsapp.com/send?phone=+51984125256&amp;text=Quiero%20saber%20mas%20de%20iFacturacion"> 984 125 256</a>
          </li>
        </ul>
      </section>
    );
  }
}
