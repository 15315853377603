import React, { useEffect } from 'react';
import { Html5QrcodeScanner } from 'html5-qrcode';

const BarcodeScanner = ({ onScanSuccess, onScanError }) => {
  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader", // ID del contenedor
      {
        fps: 10, // Frames per second
        qrbox: 250, // Tamaño del área de escaneo
      },
      false // Verbose (para mensajes de depuración)
    );

    // Renderizar el escáner con las funciones de callback
    html5QrcodeScanner.render(
      (decodedText) => {
        onScanSuccess(decodedText); // Llama a la función de éxito
      },
      (error) => {
        if (onScanError) {
          onScanError(error); // Llama a la función de error si se proporciona
        }
      }
    );

    // Limpiar el escáner al desmontar el componente
    return () => {
      html5QrcodeScanner.clear().catch((err) => {
        console.error("Error limpiando el escáner:", err);
      });
    };
  }, [onScanSuccess, onScanError]);

  return <div id="reader" style={{ width: '100%' }} />;
};

export default BarcodeScanner;
