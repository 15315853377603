// Resources
import React,{useEffect} from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';

// Dependencies

import Home from './Home/Home';
import Solution from './SolutionPage/SolutionPage';
import Blog from './Blog/Blog';
import ContactUs from './ContacUs/ContactUs';
import DownloadVoucher from './DownloadVoucher/DowloadVoucher';
import BlogReadMore from './Blog/BlogReadMorePages/BlogReadMore';
import PagEncuesta from './EncuestaSatisfaccion/PagEncuesta';
import EmpresaObligada from './EmpresaObligada/EmpresaObligada';
import RegistroUs from './RegistroUs/RegistroUs';
import EncuestaFinalizada from './EncuestaSatisfaccion/FinalizacionEncuesta/EncuestaFinalizada';
import Novedades from './Novedades/PagNovedades';
import PagLibroReclamaciones from './LibroReclamaciones/PagReclamaciones';

import LibroRec from './LibroReclamaciones/FormReclamaciones/FormRec.jsx'



import Empresa from './LibroReclamaciones/FormReclamaciones/Empresa';
import Eventos from './Eventos/formEventos/BuscarEventos.jsx';
import Evento from './Eventos/formEventos/Evento.jsx';
import RegistroAsistente from './Eventos/formEventos/Registro.jsx';

import Libro from './LibroReclamaciones/FormReclamaciones/LibroUrgente.jsx'

import PDF from './LibroReclamaciones/FormReclamaciones/pdf';

import PagPagos from './Pagos/Pagos';
import PagoCulqi from './Pagos/pagosCulqi';
import NuevaEmpresa from './CrearEmpresa/EmpresaNueva';
import Script from './Script/Script';
import ReactGa from 'react-ga';
import EmpresaNueva from './CrearEmpresa/EmpresaNueva';
import Nubefact from './Nubefact/Nubefact';
import NubefactAnulacion from './Nubefact/NubefactAnulacion';
function App() {
   useEffect(()=>{
     ReactGa.initialize('UA-170969527-1')
     //to report page view
     ReactGa.pageview(window.location.pathname + window.location.search)
   }, [])
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/soluciones">
          <Solution />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
        <Route exact path="/contacto">
          <ContactUs />
        </Route>
        <Route exact path="/descargarcomprobante">
          <DownloadVoucher />
        </Route>
        <Route exact path="/blog/leermas/:blogId">
          <BlogReadMore />
        </Route>
        <Route exact path="/encuesta/:idEncuesta" component={PagEncuesta}>
           {/* <PagEncuesta/>    */}
        </Route> 
        <Route exact path="/empresa-obligada">
          <EmpresaObligada />
        </Route>
        <Route exact path='/esencial' component={() => { 
          window.location.href = 'https://www.youtube.com/watch?v=irvw2slsVtE&list=PLwlGdxSKva3MqMkAr70q0k_2cMJHe_Lb2'; 
          return null;
        }}/>
        <Route exact path='/registro'>
           <RegistroUs />
        </Route>
        <Route exact path='/encuesta'>
            <EncuestaFinalizada/>
        </Route>
        <Route exact path='/novedades'>
            <Novedades />
        </Route>
        <Route exact path='/libro-reclamaciones'>
            <PagLibroReclamaciones/>
        </Route>
        <Route exact path= '/LibroReclamaciones/:ruc'>
          <Empresa/>
        </Route>

        <Route path='/LibroElectronicoReclamaciones'>
          <LibroRec/>

        </Route>

        <Route exact path= '/Eventos/'>
          <Eventos/>
        </Route>
        <Route exact path= '/Asistencia/:codigo'>
          <Evento/>
        </Route>
        <Route exact path='/Invitados/:codigo'>
          <RegistroAsistente/>
        </Route>

        <Route path='/pdfPrueba'>
          <PDF/>
        </Route>


        <Route exact path="/pago/:rucEmisor/:totalPago/:descripcionPago" component={PagPagos}></Route>
        <Route exact path="/pago/culqi/:totalPago" component={PagoCulqi}></Route>
        <Route exact path="/script">
          <Script />
        </Route>
        <Route exact path="/empresaNueva/:plan" component={EmpresaNueva} />
        <Route exact path='/cpe/:idticket' component={Nubefact}/>
        <Route exact path='/anulacion/:idticket' component={NubefactAnulacion}/>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
