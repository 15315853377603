/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from 'react';

// Resources
import './DescargaComprobante.css';

export default function DescargaComprobante() {
  return (
    <div className="comprobante">
      <div>
        <p className="comprobante__text">DESCARGA TU COMPROBANTE ELECTRÓNICO</p>
      </div>
      <div>
        <a className="btn" href="http://ifacturacion.pe/descargarcomprobante">Descargar</a>
        {/* <a className="btn" href="/descargarcomprobante">Descargar</a> */}
      </div>
    </div>
  );
}
