import React from 'react';

import Header from '../Home/1-Header/Header';
import Footer from '../Home/3-Footer/Footer';
import { CulqiProvider, Culqi } from "react-culqi";
import apiCulqi from '../../../apiCulqi';
import api from '../../../api';
import './EmpresaNueva.css';

export default class EmpresaNueva extends React.Component{
    constructor(props){
        super(props)
        this.state={
            pago:this.props.match.params.plan,
            type: 'password',
            respuetaCulqi:undefined,
            form:{
                email:'',
                contrasena:'',
                contacto:'',
                celular:'',
                ruc:''
            },
            data:{
                resultado:[]
            },

        }
        this.handleChange=this.handleChange.bind(this);
        this.showHide = this.showHide.bind(this);
        this.CrearCargos= this.CrearCargos.bind(this);
        this.GuardarDatos=this.GuardarDatos.bind(this);
    }

    showHide(e){
        e.preventDefault();
        this.setState({
          type: this.state.type === 'password' ? 'input' : 'password'
        })
      }

    handleChange(e){
        this.setState({
          form: {
            ...this.state.form,
            [e.target.name]: e.target.value,
          },
        });
        this.setState({mostrar:false})

      }


      async CrearCargos(variables){
          //primero crear el cargo if cargo is igual a ok
          try{
            const crear= await apiCulqi.cargos.crearCargo(variables);
            if(crear.object=== "charge"){
                    this.GuardarDatos();
            }else{
                alert("Ocurrio un Error, en el proceso de pago, intente mas luego");
                //console.log("ERROR");

            }

          }catch(error){

            alert("Ocurrio un Error, en el proceso de pago, intente mas luego");
              // console.log(error);
          }

          //guardar datos
          //else mostrar mensajes de error un alert


      }
      async GuardarDatos(){
            try{

                const variables={
                'Id_ClienteProveedor':0,
                'Cod_TipoDocumento':'006',
                'Nro_Documento':this.state.form.ruc,
                'Cliente':this.state.form.contacto,
                'Ap_Paterno':"",
                'Ap_Materno':"",
                'Nombres':"",
                'Direccion':"",
                'Cod_EstadoCliente':"001",
                'Cod_CondicionCliente':"01",
                'Cod_TipoCliente':"002",
                'RUC_Natural':this.state.form.contrasena,
                'Foto':null,
                'Firma':null,
                'Cod_TipoComprobante':"",
                'Cod_Nacionalidad':"",
                'Fecha_Nacimiento':"",
                'Cod_Sexo':"",
                'Email1':this.state.form.email,
                'Email2':"",
                'Telefono1':this.state.form.celular,
                'Telefono2':"",
                'Fax':"",
                'PaginaWeb':"",
                'Cod_Ubigeo':"",
                'Cod_FormaPago':"005",
                'Limite_Credito':0,
                'Obs_Cliente':"",
                'Num_DiaCredito':0,
                'Ubicacion_EjeX':0,
                'Ubicacion_EjeY':0,
                'Ruta':"",
                'Cod_Usuario':"WEB"
            }

                const data= await api.clienteProveedor.guardarClienteProvedor(variables);
                console.log(data);
                if( data.message==="datoGuardado"){
                    setTimeout(" window.location.href = '/';",500);

                }else{
                    alert("Ocurrio un Error, en el proceso de pago, intente mas luego");
                }


            }catch(error)
            {
                alert("Ocurrio un Error, en el proceso de pago, intente mas luego");

            // console.log('Error',error)
            }


}
render(){
    const monto=(parseInt(this.state.pago)*100);
        return(

            <section>
                    <Header />
                        <div className="Empresa">
                            <div><h1>Nueva Empresa</h1></div>
                            <div>
                                <form className="Empresa__Form">
                                        <div className="Empresa__Form-bloques">
                                            <div>
                                                <label>Email (será el usuario)</label>
                                            </div>
                                            <input
                                            type="email"
                                            name="email"
                                            onChange={this.handleChange}
                                            value={this.state.form.email}/>

                                        </div>
                                        <div className="Empresa__Form-bloques">

                                                <label>Contraseña</label>
                                          <div>
                                            <input
                                                id="contrasena"
                                                type={this.state.type}
                                                name="contrasena"
                                                onChange={this.handleChange}
                                                value={this.state.form.contrasena}/>
                                                <span className="show" type="checkbox" onClick={this.showHide}>{this.state.type === 'password' ? 'Mostrar' : 'Ocultar'}</span>
                                          </div>
                                        </div>
                                        <div className="Empresa__Form-bloques">
                                             <div>
                                                <label>Persona de Contacto</label>

                                             </div>
                                            <input
                                            type="text"
                                            name="contacto"
                                            onChange={this.handleChange}
                                            value={this.state.form.contacto}/>

                                        </div>
                                        <div className="Empresa__Form-bloques">
                                            <div>
                                                 <label>Telefono móvil</label>
                                            </div>
                                            <input
                                            type="text"
                                            name="celular"
                                            minLength={9}
                                            maxLength={9}
                                            onChange={this.handleChange}
                                            value={this.state.form.celular}/>

                                        </div>
                                        <div className="Empresa__Form-bloques">
                                            <div>
                                                <label>Ruc de empresa o persona para la cuenta </label>
                                            </div>

                                            <input
                                            type="text"
                                            name="ruc"
                                            minLength={9}
                                            maxLength={9}
                                            onChange={this.handleChange}
                                            value={this.state.form.ruc}/>

                                        </div>
                                </form>
                                <div>
                                <CulqiProvider
                                    publicKey="pk_test_d7bcda613a71bce5"
                                    amount={monto}
                                    title="Servicio de Pagos"
                                    description=""
                                    onToken={token => {
                                        // console.log("token received", token);
                                        this.state.respuetaCulqi=token;
                                        const datos={
                                            amount: monto,
                                            currency_code: "PEN",
                                            email: this.state.form.email,
                                            source_id:this.state.respuetaCulqi.id
                                        }

                                        // console.log(datos);
                                        this.CrearCargos(datos);

                                    }}
                                    onError={error => {
                                        console.error("something bad happened", error);
                                    }}
                                    // Uncomment `options` to see customizations take place
                                    options={{
                                        style: {
                                        maincolor: "#d92530",
                                        buttontext: "white",
                                        maintext: "darkorange",
                                        desctext: "pink",
                                        logo: "https://www.ifacturacion.pe/image/iconoPale.ico"
                                        }
                                    }}
                                    >
                                    <div>
                                        <Culqi>
                                        {({ openCulqi, setAmount, amount }) => {
                                            return (

                                            <div className="centrar">
                                                <button
                                                type="submit"
                                                className="button"
                                                onBlur={this.handleSubmit}
                                                onClick={openCulqi}>
                                                    PAGAR S/ {this.state.pago}.00
                                                </button>
                                            </div>


                                            );
                                        }}
                                        </Culqi>
                                    </div>
                                    </CulqiProvider>
                                </div>
                            </div>
                        </div>
                    <Footer />
            </section>
        )
    }

}
